@if (keys) {
  @if (data$ | async; as state) {
    <div class="card">
      <div class="card-body">
        @if (state.payouts; as data) {
          @if (data.length > 0) {
            <!-- <div *ngIf="agents.error">
            <fa-icon [icon]="faExclamationTriangle"></fa-icon>
            <h3 class="my-4">An error occurred</h3>
            <p>{{ agents.error }}</p>
          </div> -->
            @if (data) {
              <div class="overflow-x-auto" #container>
                <table
                  class="table table-sm table-pin-rows table-pin-cols"
                  #table
                >
                  <thead>
                    <tr>
                      <!-- <th>Extension</th>
                    <th>Virtual Agents</th> -->
                      <th>
                        <div class="flex items-center gap-2">
                          <div
                            class="grow"
                            (click)="toggleSort('agent.firstName')"
                          >
                            Agent
                            @if (filter['order']?.field === 'agent.firstName') {
                              <fa-icon
                                class="ml-2"
                                [icon]="
                                  filter['order']?.direction === 'asc'
                                    ? faArrowUp
                                    : faArrowDown
                                "
                              ></fa-icon>
                            }
                          </div>
                          @if (showArrows) {
                            <button
                              class="btn btn-sm btn-circle btn-outline"
                              (click)="scrollLeft()"
                            >
                              <fa-icon [icon]="faChevronLeft"></fa-icon>
                            </button>
                          }
                        </div>
                      </th>
                      <td>Agent NPN</td>
                      @if (showAllProfiles) {
                        <td>Agency</td>
                      }
                      <td (click)="toggleSort('consumer.firstName')">
                        Consumer
                        @if (filter['order']?.field === 'consumer.firstName') {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      <td>Policy #</td>
                      <td (click)="toggleSort('statementDate.timestamp')">
                        Statement Date
                        @if (
                          filter['order']?.field === 'statementDate.timestamp'
                        ) {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      <td (click)="toggleSort('cycle.name')">
                        Cycle
                        @if (filter['order']?.field === 'cycle.name') {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      <td>Payout Type</td>
                      <td (click)="toggleSort('carrier.id')">
                        Carrier
                        @if (filter['order']?.field === 'carrier.id') {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      @for (k of keys; track k.id) {
                        @if (k.primitive) {
                          <td (click)="toggleSort(k.keyDot)">
                            {{ k.title }}
                            @if (filter['order']?.field === k.keyDot) {
                              <fa-icon
                                class="ml-2"
                                [icon]="
                                  filter['order']?.direction === 'asc'
                                    ? faArrowUp
                                    : faArrowDown
                                "
                              ></fa-icon>
                            }
                          </td>
                        }
                      }
                      <td (click)="toggleSort('payout.personal')">
                        <!-- @if (filter['agency-filter-type'] === 'MY_PAYOUTS') {
                        Net Commission by Agent
                      } @else if (filter['agency-filter-type'] === 'BUSINESS_WRITTEN') {
                        How much agent made
                      } @else {
                        Payouts Personal
                        } -->
                        Payout Value
                        @if (filter['order']?.field === 'payout.personal') {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      <td
                        (click)="
                          toggleSort('identifiedResult.operationMessage')
                        "
                      >
                        Identified Payout
                        @if (
                          filter['order']?.field ===
                          'identifiedResult.operationMessage'
                        ) {
                          <fa-icon
                            class="ml-2"
                            [icon]="
                              filter['order']?.direction === 'asc'
                                ? faArrowUp
                                : faArrowDown
                            "
                          ></fa-icon>
                        }
                      </td>
                      <td>Transaction Amount</td>
                      <td>Account</td>
                      <td>Note</td>
                      <th>
                        <div class="flex items-center gap-2">
                          @if (showArrows) {
                            <button
                              class="btn btn-sm btn-circle btn-outline"
                              (click)="scrollRight()"
                            >
                              <fa-icon [icon]="faChevronRight"></fa-icon>
                            </button>
                          }
                          <span class="grow"> Actions </span>
                        </div>
                      </th>
                      <!-- <th (click)="toggleSort('teamLeader')">
                    Team Leader
                    <fa-icon
                      *ngIf="filter['order']?.key === 'teamLeader'"
                      class="ml-2"
                [icon]="
                  filter['order']?.direction === 'asc' ? faArrowUp : faArrowDown
                "></fa-icon>
                  </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    @for (d of data; track d.id) {
                      <tr>
                        <th>
                          <div class="flex items-center gap-2">
                            <app-user-card
                              class="grow"
                              [userId]="d.agent?.id"
                              [user]="d.agent"
                            ></app-user-card>

                            @if (
                              d.payoutType !== PayoutType.PT_AUTO_CALCULATED &&
                              d.user &&
                              d.user.id &&
                              d.user.id !== d.agent?.id
                            ) {
                              <app-tooltip
                                text="Amount is paid to"
                                [userId]="d.user.id"
                              >
                                <fa-icon
                                  [icon]="faExclamationTriangle"
                                ></fa-icon>
                              </app-tooltip>
                            }
                          </div>
                        </th>
                        <td>{{ d?.agent?.npn }}</td>
                        @if (showAllProfiles) {
                          <td>
                            @if (d?.agency?.id) {
                              <app-agency-tag
                                [agencyId]="d.agency?.id"
                              ></app-agency-tag>
                            }
                          </td>
                        }
                        <td>
                          @if (
                            d?.consumer?.firstName === d?.consumer?.lastName
                          ) {
                            {{ d?.consumer?.firstName }}
                          } @else {
                            {{ d?.consumer?.firstName }}
                            {{ d?.consumer?.lastName }}
                          }
                        </td>
                        <!-- <td>{{ getId(d?.id) }}</td> -->
                        <td>{{ d?.policyId }}</td>
                        <td>
                          {{ d.statementDate?.timestamp | date: 'short' }}
                        </td>
                        <td>
                          @if (d.cycle?.name) {
                            <span
                              class="badge badge-outline h-auto rounded-xl"
                              >{{ d.cycle?.name }}</span
                            >
                          }
                        </td>
                        <td>
                          @if (d.payoutType) {
                            @if (payoutTypeMap[d.payoutType]; as payoutType) {
                              <span
                                class="badge badge-outline h-auto rounded-xl gap-2"
                              >
                                <fa-icon [icon]="payoutType.icon"></fa-icon>
                                {{ payoutType.title }}
                              </span>
                            }
                          }
                        </td>
                        <td>
                          @if (d.carrier && d.carrier.id) {
                            @if (getKey(carrierMap, d.carrier.id); as carrier) {
                              <div
                                class="flex flex-row gap-2 items-center rounded-full pr-2"
                              >
                                @if (carrier?.profileImage) {
                                  <div class="w-10 object-contain">
                                    <img [src]="carrier.profileImage" />
                                  </div>
                                }
                                @if (!carrier?.profileImage) {
                                  <div
                                    class="w-10 h-10 flex items-center justify-center shrink-0"
                                  >
                                    <fa-icon [icon]="faUserTie"></fa-icon>
                                  </div>
                                }
                                <div>
                                  <p>{{ carrier?.name }}</p>
                                </div>
                              </div>
                            }
                          }
                        </td>
                        @for (k of keys; track k.id) {
                          @if (k.primitive) {
                            <td>
                              @if (k.key) {
                                <span>
                                  {{ getValue(d, k) }}
                                </span>
                              } @else {
                                @if (d) {
                                  {{ getKey(d, k.id) }}
                                }
                              }
                            </td>
                          }
                        }
                        <td>
                          {{ d.payout?.personal | currency }}
                        </td>
                        <td>
                          {{ getCommissionIdentifiedText(d) }}
                        </td>
                        <td>
                          {{ d.transaction?.amount | currency }}
                        </td>
                        <td>
                          @if (d.transaction?.account?.id) {
                            <app-account-card
                              [accountId]="d.transaction?.account?.id"
                              [owner]="d.transaction?.account?.owner"
                            ></app-account-card>
                          }
                        </td>
                        <td>{{ d.note }}</td>
                        <th>
                          <div class="flex justify-end">
                            <button
                              type="button"
                              class="btn btn-circle btn-ghost"
                              (click)="showPayout(d)"
                            >
                              <fa-icon [icon]="faEye"></fa-icon>
                            </button>
                          </div>
                        </th>
                        <!-- <td>
                    <div class="flex flex-row gap-2 items-center rounded-full pr-2">
                      <div class="avatar" *ngIf="d?.teamLeader?.profileImage">
                        <div class="w-10 rounded-full">
                          <img [src]="d.teamLeader.profileImage" />
                        </div>
                      </div>
                      <div
                        class="w-10 h-10 flex items-center justify-center shrink-0"
                        *ngIf="!d?.profileImage">
                        <fa-icon [icon]="faUserTie"></fa-icon>
                      </div>
                      <div>
                        <p>
                          {{ d.teamLeader?.firstName }} {{ d.teamLeader?.lastName }}
                        </p>
                      </div>
                    </div>
                  </td> -->
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          } @else {
            <h3 class="text-center">
              <fa-icon [icon]="faExclamationTriangle"></fa-icon> No payouts
              found
            </h3>
          }
          @if (state.loading || !state.payouts) {
            <div class="skeleton h-16 w-full"></div>
          }
        }
      </div>
    </div>
  } @else {
    <div class="skeleton h-16 w-full"></div>
  }
}

<!-- <app-payouts-detail-modal #payoutsDetailModal></app-payouts-detail-modal> -->
