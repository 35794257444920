import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ModalComponent } from '../../../ui-components/modal/modal.component';
import {
  faCircle,
  faCircleCheck,
  faEye,
  faNotEqual,
} from '@fortawesome/pro-regular-svg-icons';
import {
  commissionOriginMap,
  commissionRateMap,
  commissionTypeMap,
  monthMap,
  planTypeMap,
} from 'src/app/shared/data/commissions';
import { Commission } from 'src/app/shared/models';
import { LoadingComponent } from '../../../ui-components/loading/loading.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { CurrencyPipe, DatePipe, KeyValuePipe } from '@angular/common';
import { ReversePipe } from 'src/app/shared/pipe/reverse.pipe';
import { UserCardComponent } from '../../../ui-components/user-card/user-card.component';
import { UserCacheService } from 'src/app/shared/services/user-cache.service';
import { PayoutStatisticsService } from '../../../commission-statistics/payout-statistics.service';
import { carrierMap } from '../../../commission-statistics/commission-statistics/carrier';
import {
  ModalContainerService,
  ShowModalComponents,
} from 'src/app/shared/services/modal-container.service';
import { ModalHistoryComponent } from '../../../ui-components/modal-history/modal-history.component';
import { AccountCardComponent } from '../../../account/account-card/account-card.component';
import { payoutTypeMap } from 'src/app/shared/data/payoutType';
import { TooltipComponent } from '../../../ui-components/tooltip/tooltip.component';

@Component({
  selector: 'app-payouts-detail-modal',
  standalone: true,
  imports: [
    AccountCardComponent,
    CurrencyPipe,
    DatePipe,
    FaIconComponent,
    KeyValuePipe,
    LoadingComponent,
    ModalHistoryComponent,
    ReversePipe,
    TooltipComponent,
    UserCardComponent,
  ],
  templateUrl: './payouts-detail-modal.component.html',
  styleUrl: './payouts-detail-modal.component.scss',
})
export class PayoutsDetailModalComponent implements AfterViewInit {
  private cdr = inject(ChangeDetectorRef);
  private modalContainerService = inject(ModalContainerService);
  private payoutStatisticsService = inject(PayoutStatisticsService);
  userCacheService = inject(UserCacheService);

  @Input() openWith?: { payout: Commission; userId: string; parentFilter: any };

  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalComponent;
  // @ViewChild('payoutsDetailModal')
  // payoutsDetailModal?: PayoutsDetailModalComponent;

  faCircle = faCircle;
  faCircleCheck = faCircleCheck;
  faEye = faEye;
  faNotEqual = faNotEqual;

  loadingRelated = true;

  parentFilter?: any;
  payout?: Commission;
  userId?: string;

  carrierMap = carrierMap;
  commissionRateMap = commissionRateMap;
  planTypeMap = planTypeMap;

  commissionOriginMap = commissionOriginMap;
  commissionTypeMap = commissionTypeMap;
  payoutTypeMap = payoutTypeMap;
  monthMap = monthMap;

  relatedPayouts?: Commission[];

  ltv?: number;
  ltvTotal?: number;

  ngAfterViewInit() {
    if (this.openWith) {
      this.open(
        this.openWith.payout,
        this.openWith.userId,
        this.openWith.parentFilter,
      );
      this.cdr.detectChanges();
    }
  }

  open(payout: Commission, userId: string, parentFilter: any) {
    console.log('open payout', { payout, userId });
    this.payout = payout;
    this.parentFilter = parentFilter;
    this.userId = userId;

    if (
      this.payout?.executive?.aggregatedExecutiveRuleCommissions
        ?.executiveLadder
    ) {
      for (const user of this.payout?.executive
        ?.aggregatedExecutiveRuleCommissions?.executiveLadder) {
        this.userCacheService.addToCache(user);
      }
    }

    this.loadRelatedPayouts();

    this.modal?.show();
  }

  showPayout(payout: any) {
    console.log('editPayout', payout);
    if (!this.userId) {
      return;
    }
    // this.payoutsDetailModal?.open(payout, this.userId, this.parentFilter);

    this.modalContainerService.open(ShowModalComponents.PAYOUT, {
      payout,
      userId: this.userId,
      parentFilter: this.parentFilter,
    });
  }

  loadRelatedPayouts() {
    if (!this.payout?.policyId) {
      console.log('no policy id');
      return;
    }
    this.loadingRelated = true;
    const filter = {
      ['policy-id']: [this.payout?.policyId],
      ['user-id']: [this.userId],
      ['agency-filter-type']:
        this.parentFilter?.['agency-filter-type'] ?? 'MY_PAYOUTS',
      ['exclude-id']: [this.payout?.id],
    };

    console.log('filter related', filter);

    this.payoutStatisticsService
      .loadAll(filter, this.userId!, 10000)
      .then((res) => {
        console.log('search res', res);

        if (res.source) {
          this.relatedPayouts = res.source.map((payout: unknown) =>
            Commission.fromJSON(payout),
          );

          if (this.relatedPayouts) {
            let totalPayout = 0;

            if (this.payout?.payout?.total && this.payout.payout.total > 0) {
              totalPayout += this.payout.payout.total;
            }

            let myPayout = 0;
            // if (this.userId) {
            //   myPayout = this.payout?.payout?.byUser[this.userId] || 0;
            // } else
            if (
              this.payout?.payout?.personal &&
              this.payout.payout.personal > 0
            ) {
              myPayout += this.payout.payout?.personal;
            }

            if (
              this.payout?.identifiedResult &&
              this.payout.identifiedResult.pennyTolerance
            ) {
              if (
                this.payout.identifiedResult.pennyToleranceOwner ===
                this.payout.user?.id
              ) {
                myPayout += this.payout.identifiedResult.pennyTolerance;
              }
              totalPayout += this.payout.identifiedResult.pennyTolerance;
            }

            for (const payout of this.relatedPayouts) {
              if (payout.payout?.total && payout.payout?.total > 0) {
                totalPayout += payout.payout.total;
              }

              if (payout.agent && payout.agent.id)
                this.userCacheService.addToCache(payout.agent.id, payout.agent);

              // if (this.userId) {
              //   // if (payout.payout?.byUser[this.userId]) {
              //   //   myPayout += payout.payout?.byUser[this.userId];
              //   // } else
              // } else
              if (payout.payout?.personal && payout.payout?.personal > 0) {
                myPayout += payout.payout?.personal;
              }

              if (
                payout.identifiedResult &&
                payout.identifiedResult.pennyTolerance
              ) {
                if (
                  payout.identifiedResult.pennyToleranceOwner ===
                  payout.user?.id
                ) {
                  myPayout += payout.identifiedResult.pennyTolerance;
                }
                totalPayout += payout.identifiedResult.pennyTolerance;
              }
            }
            this.ltv = myPayout;
            if (myPayout != totalPayout) {
              this.ltvTotal = totalPayout;
            }
          }
        }

        this.loadingRelated = false;
        this.cdr.detectChanges();
      });
  }

  modalDismissed() {
    delete this.payout;
    delete this.parentFilter;
    delete this.userId;
    delete this.relatedPayouts;
    delete this.ltv;
    delete this.ltvTotal;

    this.modalDismissedFired.emit();
    this.cdr.detectChanges();
  }
}
