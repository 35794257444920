import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { AsyncPipe, CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import pack from '../../package.json';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { ModalContainerComponent } from './shared/components/ui-components/modal-container/modal-container.component';
import {
  ModalContainerService,
  ShowModalContainer,
} from './shared/services/modal-container.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [AsyncPipe, CommonModule, ModalContainerComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  updateService = inject(CheckUpdateService); // do not remove
  private platformId = inject(PLATFORM_ID);
  private modalContainerService = inject(ModalContainerService);
  private renderer = inject(Renderer2);

  title = 'enrollhere-commissions';

  version = pack.version;

  showModals$: Observable<ShowModalContainer[]>;

  constructor() {
    this.showModals$ = this.modalContainerService.showModals$;
  }

  ngOnInit() {
    this.loadHeapScript(environment.heapEnvId);
  }

  private loadHeapScript(envId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.text = `
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || [];
      heap.load = function(e, t) {
        window.heap.envId = e;
        window.heap.clientConfig = t = t || {};
        window.heap.clientConfig.shouldFetchServerConfig = !1;
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.src = "https://cdn.us.heap-api.com/config/" + e + "/heap_config.js";
        var r = document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(a, r);
        var n = [
          "init", "startTracking", "stopTracking", "track", "resetIdentity", "identify", "getSessionId", "getUserId", "getIdentity",
          "addUserProperties", "addEventProperties", "removeEventProperty", "clearEventProperties", "addAccountProperties",
          "addAdapter", "addTransformer", "addTransformerFn", "onReady", "addPageviewProperties", "removePageviewProperty",
          "clearPageviewProperties", "trackPageview"
        ];
        var i = function(e) {
          return function() {
            var t = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
              name: e,
              fn: function() {
                heap[e] && heap[e].apply(heap, t);
              }
            });
          };
        };
        for (var p = 0; p < n.length; p++) heap[n[p]] = i(n[p]);
      };
      heap.load("${envId}", { disableTextCapture: true });
    `;
      this.renderer.appendChild(document.body, script);
    }
  }
}
