{
  "name": "enrollhere-commissions",
  "version": "0.2.10",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:enrollhere-commissions": "node dist/enrollhere-commissions/server/server.mjs",
    "replace-timestamp": "node ./replace.build.js",
    "rebase-demo": "git checkout demo;git rebase dev",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "deploy:dev": "firebase deploy --only hosting:enrollhere-commissions --project enroll-here-dev",
    "deploy:prod:temp": "ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here --config firebase.temp.json",
    "deploy:demo:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.demo.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here-demo --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "deploy:dev:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.development.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-commissions --project enroll-here-dev --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-commissions ./dist/enrollhere-commissions/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-commissions ./dist/enrollhere-commissions/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/common": "18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/platform-server": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@angular/service-worker": "^18.2.13",
    "@angular/ssr": "^18.2.12",
    "@floating-ui/dom": "^1.6.12",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/pro-regular-svg-icons": "^6.7.1",
    "@gorules/zen-engine": "^0.35.0",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@sentry/angular": "^8.41.0",
    "@sentry/cli": "^2.39.1",
    "@swimlane/ngx-charts": "^21.0.0",
    "express": "^4.21.1",
    "firebase-functions": "^6.1.1",
    "lottie-web": "^5.12.2",
    "ngx-cookie-service": "^18.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "replace-in-file": "^8.2.0",
    "rxjs": "~7.8.1",
    "ssr-window": "^4.0.2",
    "tslib": "^2.8.1",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-devkit/core": "^18.2.12",
    "@angular-devkit/schematics": "^18.2.12",
    "@angular-eslint/eslint-plugin": "^18.4.2",
    "@angular-eslint/eslint-plugin-template": "^18.4.2",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@ngrx/eslint-plugin": "^18.1.1",
    "@ngrx/schematics": "^18.1.1",
    "@schematics/angular": "^18.2.12",
    "@swc-node/register": "~1.10.9",
    "@swc/cli": "~0.5.1",
    "@swc/core": "~1.9.3",
    "@swc/helpers": "~0.5.15",
    "@swc/jest": "~0.2.37",
    "@types/express": "^5.0.0",
    "@types/jasmine": "~5.1.5",
    "@types/jest": "^29.5.14",
    "@types/node": "^22.10.1",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "^8.16.0",
    "autoprefixer": "^10.4.20",
    "daisyui": "^4.12.14",
    "eslint": "~8.57.1",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-core": "~5.4.0",
    "jest": "^29.7.0",
    "jest-environment-node": "^29.7.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.49",
    "prettier": "^3.4.1",
    "tailwindcss": "^3.4.15",
    "ts-jest": "^29.2.5",
    "ts-node": "10.9.2",
    "typescript": "~5.5.4"
  },
  "packageManager": "pnpm@9.14.2"
}
